import Config from '../Config/Config';
import _ from 'lodash';

class API {

  formatParams = (params) => {
    let paramString = '';
    if (!_.isEmpty(params)){
      paramString += '?';
      for (let k in params){
        if (paramString != '?'){
          paramString += '&';
        }
        paramString += `${k}=${params[k]}`;
      }
    }
    return paramString;
  }


  makeRequest = (path, method, params, data, headers={}) => {
    const paramString = this.formatParams(params);
    const fullUrl = `${Config.baseApiUrl}/${path}${paramString}`;
    const obj = {
      method: method,
      //credentials: Config.credentials,
      headers: {
        ...headers
      },
      body: data ? data : null
    };
    return fetch(fullUrl, obj)
  }

  get = (path, params, headers) => {
    return this.makeRequest(path, 'GET', params, null, headers)
  }

  post = (path, params, data, headers) => {
    headers = headers ? headers : {'Accept': 'application/json', 'Content-Type': 'application/json'};
    return this.makeRequest(path, 'POST', params, data. headers)
  }

}

export default new API();
