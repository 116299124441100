import {observable, action, computed, makeObservable} from 'mobx';
import moment from 'moment';
import API from '../API/API';

class PVAsset {

  @observable azimuth = '';
  @observable tilt = '';
  @observable capacity = '';

  @observable hasFocused = {
    azimuth: false,
    tilt: false,
    capacity: false
  }

  @observable calcResult = null;

  constructor(){
    makeObservable(this);
  }

  @action onChangeAzimuth = (v) => this.azimuth = v;
  @action onChangeTilt = (v) => this.tilt = v;
  @action onChangeCapacity = (v) => this.capacity = v;

  @action setFocused = (key, hasFocused=true) => {
    this.hasFocused[key] = hasFocused;
  }

  @action runCalculation = async (lat, lng, startDate, endDate) => {
    const params = {
      longitude: lng,
      latitude: lat,
      capacity_kw: this.capacity,
      azimuth: this.azimuth,
      tilt: this.tilt,
      start_date: startDate,
      end_date: endDate,
      frequency: '1D'
    }
    const r = await API.get('api/solar', params);
    const data = await r.json();
    this.calcResult = {
      mean: {
        x: data['valid_datetime'],
        y: data['Physical_Forecast'],
      },
      clearsky: {
        x: data['valid_datetime'],
        y: data['Clearsky_Forecast'],
      }
    }
  }

  @computed get isAzimuthValid(){return this.azimuth !== null && this.azimuth >= 0 && this.azimuth < 360}
  @computed get isTiltValid(){return this.tilt !== null && this.tilt >= 0 && this.tilt <= 90}
  @computed get isCapacityValid(){return this.capacity !== null && this.capacity > 0}

  @computed get allFieldsValid(){
    return this.isAzimuthValid && this.isTiltValid && this.isCapacityValid;
  }


}

export default PVAsset;
