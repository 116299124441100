import React from 'react';
import Select from 'react-select';

const Dropdown = (props) => (
  <div style={props.style}>
    <Select {...props}/>
  </div>
);

export default Dropdown;
