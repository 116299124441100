import React from 'react';
import styled from 'styled-components';
import {theme} from '../../Theme';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`

const NavBar = (props) => (
  <Container style={{...theme.navbar, ...props.style}}>
    {props.children}
  </Container>
);

export default NavBar;
