import React from 'react';
import styled from 'styled-components';
import {S3} from '../UI/Text';
import TextInput from '../UI/TextInput';
import {observer} from 'mobx-react';

const Col = styled.div`
  display: flex;
  flex-direction: column;
`;

const PVConfig = ({asset}) => {
  return (
    <>
    <Col style={{marginTop: 16}}>
      <S3 style={{marginLeft: 8}}>
        Azimuth
      </S3>
      <TextInput
        value={asset.azimuth}
        onChange={asset.onChangeAzimuth}
        isValid={!asset.hasFocused.azimuth || asset.isAzimuthValid}
        onBlur={() => asset.setFocused('azimuth')}
        errorMessage='Invalid azimuth'
        placeholder='0 to 359°'
      />
    </Col>
    <Col style={{marginTop: 16}}>
      <S3 style={{marginLeft: 8}}>
        Tilt
      </S3>
      <TextInput
        placeholder='0 to 90°'
        value={asset.tilt}
        onChange={asset.onChangeTilt}
        isValid={!asset.hasFocused.tilt || asset.isTiltValid}
        onBlur={() => asset.setFocused('tilt')}
      />
    </Col>
    <Col style={{marginTop: 16}}>
      <S3 style={{marginLeft: 8}}>
        Capacity (kW)
      </S3>
      <TextInput
        placeholder='0 kW'
        value={asset.capacity}
        onChange={asset.onChangeCapacity}
        isValid={!asset.hasFocused.capacity || asset.isCapacityValid}
        onBlur={() => asset.setFocused('capacity')}
      />
    </Col>
    </>

  )
}
export default observer(PVConfig);
