import React from 'react';
import styled from 'styled-components';

const StyledButton = styled.button`
  padding: 12px;
  border-style: none;
  background-color: #4265FB;
  color: white;
  letter-spacing: 1.25px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 14px;
  :disabled {
    background-color: #d7d7d7;
    cursor: default;
  }
`

const Button = (props) => (
  <StyledButton {...props}>
    <div>
      {props.text}
    </div>
  </StyledButton>
);

export default Button;
