import React, { Component } from 'react';
import styled from 'styled-components';

const StyledInput = styled.input`
  border-color: ${props => props.isValid === false ? 'red' : '#d7d7d7'};
  padding: 8px;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  font-size: 13px;
`

class TextInput extends Component {

  onChange = (e) => {
    if (this.props.onChange){
      this.props.onChange(e.target.value);
    }
  }

  onFocus = (e) => {
    if (this.props.onFocus){
      this.props.onFocus(e);
    }
  }

  onBlur = (e) => {
    if (this.props.onBlur){
      this.props.onBlur(e);
    }
  }

  render() {
    return (
      <StyledInput
        {...this.props}
        onChange={this.onChange}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
      />
    );
  }

}

export default TextInput;
