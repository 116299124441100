import styled from 'styled-components';

export const S2 = styled.p`
  color: ${props => props.theme.text.title.color};
  font-size: 15px;
  letter-spacing: 0.1px;
  font-weight: 600;
  padding: 0;
  margin: 0;
`;

export const S3 = styled.p`
  color: ${props => props.theme.text.title.color};
  font-size: 13px;
  letter-spacing: 0.1px;
  font-weight: 600;
  padding: 0;
  margin: 0;
`;
