import React, { Component } from 'react';
import mapboxgl from 'mapbox-gl';
import Config from '../../Config/Config';
import styled from 'styled-components';

// https://stackoverflow.com/questions/65802002/mapbox-production-error-in-console-uncaught-referenceerror-y-is-not-defined
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

mapboxgl.accessToken = Config.mapboxAccessToken;



class Map extends Component {


  componentDidMount() {
    const map = new mapboxgl.Map({
        container: this.mapContainer,
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [this.props.lng, this.props.lat],
        zoom: this.props.zoom
    });
    this.map = map;

    const canvas = map.getCanvas();

    canvas.style.cursor = 'default';
    map.on('moveend', (e) => {
      canvas.style.cursor = 'default';
    })
    map.on('move', (e) => {
      canvas.style.cursor = 'move';
      if (this.props.onMove){
        this.props.onMove(e)
      }
    })

    map.on('click', (e) => {
      if (this.props.onClick){
        this.props.onClick(e);
      }
    })
  }

  render() {
    return (
      <div style={{position: 'relative', ...this.props.style}}>
        <div
          ref={el => this.mapContainer = el}
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            left: 0,
            bottom: 0
          }}
        >
          {this.props.children}
        </div>
      </div>

    );
  }

}

export default Map;
