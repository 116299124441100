import React, {Component} from 'react';
import {observer, inject} from 'mobx-react';
import {toJS} from 'mobx';
import './App.css';
import Map from './Components/Map/Map';
import Popup from './Components/Map/Popup';
import styled from 'styled-components';
import Theme, {theme} from './Theme';
import Dropdown from './Components/UI/Dropdown';
import TextInput from './Components/UI/TextInput';
import NavBar from './Components/UI/NavBar';
import {S2, S3} from './Components/UI/Text';
import Button from './Components/UI/Button';
import PVConfig from './Components/Asset/PVConfig';
import LineChart from './Components/Charts/LineChart';
import Divider from './Components/UI/Divider';
import LoadingIndicator from './Components/UI/LoadingIndicator';


const Row = styled.div`
  display: flex;
  flex-direction: row;
`

const Col = styled.div`
  display: flex;
  flex-direction: column;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100vh;
  width: 100%;
`

@inject('RootStore')
@observer
class App extends Component {

  constructor(){
    super();

    this.state = {
      lat: 51,
      lng: 7,
      zoom: 5,
      popup: null
    }
  }


  render() {
    const dataStore = this.props.RootStore.dataStore;
    const loc = dataStore.currentLocation;
    return (
      <Theme>
        <Container>
          <NavBar style={{height: 30, width: '100%', padding: 16, position: 'fixed', zIndex: 10, backgroundColor: 'white'}}>
            <S2>
              EnergyDataMap
            </S2>
          </NavBar>
          <Map
            style={{flex: 1}}
            ref={r => this.mapRef = r}
            zoom={this.state.zoom}
            lat={this.state.lat}
            lng={this.state.lng}
            onMove={(e) => {
              // if a popup is open, recalculate its position when map moves
              dataStore.updatePosition(this.mapRef);
            }}
            onClick={(e) => {
              dataStore.addLocation({
                lat: e.lngLat.lat,
                lng: e.lngLat.lng,
                x: e.point.x,
                y: e.point.y
              })
            }}
            >
            {loc !== null &&
              <div style={{
                position: 'absolute',
                left: loc.x-10,
                top: loc.y-10,
                height: 20,
                width: 20,
                background: theme.colors.primary,
                borderRadius: 20,
              }}>

              </div>
            }
            {loc !== null &&
              <Popup
                onClose={dataStore.onCloseLocation}
                /*x={loc.x}
                y={loc.y}*/
                x={32}
                y={96}
              >
              <Row style={{flex: 1, alignItems: 'stretch'}}>
                <Col style={{marginTop: 16}}>
                  <Row>
                    <Col>
                      <S3 style={{marginLeft: 8}}>
                        Latitude
                      </S3>
                      <TextInput
                        isValid={loc.isLatValid}
                        errorMessage='Invalid latitude'
                        placeholder='-90 to 90°'
                        value={loc.lat}
                        onChange={(lat) => {
                          loc.onChangeLat(lat);
                          if (loc.isLatValid){
                            dataStore.updatePosition(this.mapRef);
                            this.mapRef.map.jumpTo({center: [loc.lng, loc.lat]})
                          }
                        }}                      />
                    </Col>
                    <Col style={{marginLeft: 8}}>
                      <S3 style={{marginLeft: 8}}>
                        Longitude
                      </S3>
                      <TextInput
                        value={loc.lng}
                        onChange={(lng) => {
                          loc.onChangeLng(lng);
                          if (loc.isLngValid){
                            dataStore.updatePosition(this.mapRef);
                            this.mapRef.map.jumpTo({center: [loc.lng, loc.lat]})
                          }
                        }}
                      />
                    </Col>
                  </Row>
                  <Col style={{marginTop: 16}}>
                    <S3 style={{marginLeft: 8}}>
                      Year
                    </S3>
                    <Dropdown
                      value={loc.selectedYear}
                      style={{width: '100%'}}
                      options={loc.yearOptions}
                      onChange={loc.onChangeYear}
                    />
                  </Col>
                  <PVConfig
                    asset={loc.asset}
                  />
                  <Button
                    text='CALCULATE'
                    style={{marginTop: 16}}
                    disabled={!loc.asset.allFieldsValid}
                    onClick={loc.runCalculation}
                  />
                </Col>
                {(loc.chartData !== null || loc.isLoading) &&
                <>
                <Divider vertical style={{marginLeft: 16}}/>
                <Col style={{flex: 1, width: '50vw', marginLeft: 16}}>
                  {loc.isLoading &&
                  <Col style={{flex: 1, alignItems: 'center', justifyContent: 'center'}}>
                    <LoadingIndicator
                      style={{}}
                      message='Loading...'
                    />
                  </Col>
                  ||
                  <LineChart
                    datasets={loc.chartData}
                    yAxisName='Production (kWh)'
                    options={{
                      scales: {
                        yAxes: [
                          {
                            ticks: {
                              min: 0
                            }
                          }
                        ]
                      }
                    }}
                  />
                }
                </Col>
              </>
                }
              </Row>
              </Popup>
            }
          </Map>
        </Container>
      </Theme>
    );
  }

}

export default observer(App);
