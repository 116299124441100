import React from "react";
import { ThemeProvider } from "styled-components";

export const theme = {
  container: {
    border: {
      radius: 4
    }
  },
  level: {
    1: {
      backgroundColor: '#ffffff',
    },
    2: {
      boxShadow: '0px 3px 10px 0px #24242433'
    }
  },
  background: {
    backgroundColor: '#242424',
  },
  foreground: {
    backgroundColor: '#303030'
  },
  text: {
    light: {
      color: '#424242'
    },
    title: {
      color: '#1F334A'
    }
  },
  box: {
    border: {
      radius: '4px'
    },
    padding: '24px'
  },
  navbar: {
    boxShadow: '0px 4px 10px 0px #24242433'
  },
  input: {
    backgroundColor: '#484848',
    color: '#ffffffcd',
    border: {
      radius: '4px',
      color: '#424242',
    }
  },
  dropdown: {
    closed: {
      backgroundColor: '#484848'
    },
    open: {
      backgroundColor: '#484848'
    },
    options: {

    }
  },
  button: {
    primary: {
      borderColor: '#C9DDFF',
      backgroundColor: '#C9DDFF',
      borderRadius: '6px',
      color: '#484848'
    },
    secondary: {
      borderColor: '#484848',
      backgroundColor: '#484848',
      borderRadius: '6px',
    }
  },
  colors: {
    primary: '#4265FB',
  },
  vizColors: {
    green: '#a4ffc3',
    blue: '#b5d1ff',
    white: '#ffffff',
    red: '#ff8e8e',
    yellow: '#fff28e',
    purple: '#dab5ff',
    turquoise: '#b5fffd',
    pink: '#ffb5df',
  },
  fonts: ["sans-serif", "Roboto"],
  fontSizes: {
    small: "1em",
    medium: "2em",
    large: "3em"
  }
};

const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export { Theme as default };
