import React from 'react';
import {Chart, Line} from 'react-chartjs-2';
import {theme} from '../../Theme';
import moment from 'moment';
import _ from 'lodash';


class LineChart extends React.Component {

  render(){
    let {datasets, style, options} = this.props;
    const filledDatasets = datasets;

    let formattedDatasets = [];
    if (filledDatasets){
      formattedDatasets = filledDatasets.map(d => {
        return ({
            borderWidth: 1.5,
            lineTension: 0,
            ...d
        })
      });
    }


    return (
      <div style={{flex: 1, position: 'relative',  overflow: 'hidden', width: '100%', height: '100%', ...style}}>
        <Line
          plugins={this.props.plugins}
          data={{
              datasets: formattedDatasets
          }}
          options={_.merge({
            responsive: true,
            maintainAspectRatio: false,
            legend: {
                labels: {
                    fontColor: "#282828",
                }
            },
            scales: {
                yAxes: [{
                  scaleLabel: {
                    fontColor: theme.text.light.color,
                    display: this.props.yAxisName,
                    labelString: this.props.yAxisName,
                  },
                  gridLines: {
                    color: theme.colors.primary+'17',
                    zeroLineColor: theme.colors.primary+'17',
                  },
                  ticks: {
                      fontColor: theme.text.light.color,
                      autoSkip: true,
                  }
                }],
                xAxes: [{
                  scaleLabel: {
                    fontColor: theme.text.light.color,
                    display: this.props.xAxisName,
                    labelString: this.props.xAxisName,
                  },
                  gridLines: {
                    color: theme.colors.primary+'22',
                    zeroLineColor: theme.colors.primary+'22',
                  },
                  type: this.props.xAxisType ? this.props.xAxisType : 'time',
                  ticks: {
                    fontColor: theme.text.light.color,
                    autoSkip: true,
                    maxTicksLimit: 12,
                  },
                  time: {
                    tooltipFormat:'MMM DD HH:mm',
                    unit: 'day',
                    unitStepSize: 1,
                    displayFormats: {
                       'day': 'MMM DD'
                    }
                  }
                }]
          },
          hover: {
            animationDuration: 0,
            intersect: false,
            axis: 'x', // extremely important to hover multiple datasets
          },
          tooltips: {
            backgroundColor: '#565656',
            axis: 'x', // extremely important to hover multiple datasets
            intersect: false,

        },
        }, options)}
        />
      </div>
      )
  }

}



export default LineChart;
