import styled from 'styled-components';
import {MdClose} from 'react-icons/md';

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const Close = styled(MdClose)`
  cursor: pointer;
  font-size: 18px;
`

const PopupContainer = styled.div`
  position: absolute;
  top: ${props => `${props.y}px`};
  left: ${props => `${props.x}px`};
  z-index: 1;
  background: white;
  padding: 16px;
  box-shadow: ${props => props.theme.level[2].boxShadow};
  border-radius: ${props => `${props.theme.container.border.radius}px`};
`

const Popup = ({x, y, children, onClose}) => {
  return (
    <PopupContainer x={x} y={y}>
      <Row>
        <div style={{flex: 1}}/>
        <Close onClick={onClose}/>
      </Row>
      {children}
    </PopupContainer>
  )
}

export default Popup;
