import {observable, makeObservable, computed, action, toJS} from 'mobx';
import PVAsset from './PVAsset';
import {theme} from '../Theme';
import moment from 'moment';

class Location {

  @observable lat = '';
  @observable lng = '';
  @observable x = null;
  @observable y = null;

  @observable asset = null;
  @observable selectedYear = null;

  yearOptions = [];

  @observable isLoading = false;

  constructor(loc){
    makeObservable(this);

    this.lat = loc.lat;
    this.lng = loc.lng;
    this.x = loc.x;
    this.y = loc.y;

    this.asset = new PVAsset();
    const lastYear = moment().year()-1;
    this.selectedYear = {value: lastYear, label: lastYear};
    const diff = lastYear-1979+1;
    this.yearOptions = Array(diff).fill(null).map((_, i) => {
      const year = lastYear-i;
      return {value: year, label: year};
    })
  }

  @action runCalculation = async () => {
    const startDate = moment().year(this.selectedYear.value).month(0).date(1).format('YYYY-MM-DD');
    const endDate = moment().year(this.selectedYear.value).month(11).date(31).format('YYYY-MM-DD');

    this.isLoading = true;
    await this.asset.runCalculation(this.lat, this.lng, startDate, endDate);
    this.isLoading = false;
  }

  @action onChangeYear = (year) => {
    this.selectedYear = year;
  }

  @action onChangeLat = (lat) => {
    this.lat = lat;
  }

  @action onChangeLng = (lng) => {
    this.lng = lng;
  }

  @computed get chartData(){
    if (this.asset.calcResult === null){
      return null;
    }
    const data = [];
    const clearsky = [];
    const mean = this.asset.calcResult.mean;
    const cs = this.asset.calcResult.clearsky;
    for (let i=0; i<mean.x.length; i++){
      data.push({x: mean.x[i], y: mean.y[i]});
      clearsky.push({x: cs.x[i], y: cs.y[i]});
    }

    return [
      {
        label: 'Production daily',
        borderColor: theme.colors.primary,
        backgroundColor: theme.colors.primary+'11',
        pointRadius: 0,
        data: data
      },
      {
        label: 'Production clear sky daily',
        borderColor: '#d7d7d7',
        backgroundColor: '#d7d7d711',
        pointRadius: 0,
        data: clearsky
      }
    ]
  }

  @computed get isLatValid(){return this.lat !== null && this.lat >= -90 && this.lat <= 90}
  @computed get isLngValid(){return this.lng !== null && this.lng >= -180 && this.lng <= 180}

}

export default Location;
