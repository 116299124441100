import {observable, computed, action, makeObservable} from 'mobx';
import {toJS} from 'mobx';
import Location from './Location';

class DataStore {

  constructor(rootStore){
    makeObservable(this);
    this.rootStore = rootStore;
  }

  @observable locations = [];

  @action addLocation = (loc) => {
    if (this.locations.length > 0){
      const locToUpdate = this.locations[0];
      locToUpdate.lng = loc.lng;
      locToUpdate.lat = loc.lat;
      locToUpdate.x = loc.x;
      locToUpdate.y = loc.y;
    }
    else {
      const newLoc = new Location(loc);
      this.locations = [...this.locations, newLoc];
    }

  }

  @action updatePosition = (mapRef) => {
    if (mapRef){
      const locations = this.locations.map(l => {
        if (l.isLatValid && l.isLngValid){
          const point = mapRef.map.project([l.lng, l.lat]);
          l.x = point.x;
          l.y = point.y;
        }
        return l;
      })
      this.locations = [...locations];
    }
  }

  @action onCloseLocation = () => {
    this.locations = [];
  }


  @computed get currentLocation(){
    return this.locations.length > 0 ? this.locations[0] : null;
  }

}

export default DataStore;
